import React from 'react';
import { Helmet } from 'react-helmet';

import Header from 'sections/ScreverOcxProgram/Header';
import Hero from 'sections/ScreverOcxProgram/Hero';
import PagesList from 'sections/ScreverOcxProgram/PagesList';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: 'Participants',
    url: '/events/screver-ocx-program/participants',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
  {
    title: 'Casino instructions',
    url: '/events/screver-ocx-program/casino-instructions',
  },
];

const ScreverOcxProgram = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver OCX Event | Agenda"
      >
        <meta
          name="description"
          content="Get the most out of your event by having an idea of the agenda to follow. Here, you can find a program overview, helpful information about participants and feedback options, plus all the casino instructions!"
        />
        <meta name="theme-color" content="#151517"></meta>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program">
        <Header />

        <main>
          <Hero />
          <PagesList linksToPages={PAGES} />
        </main>
      </div>
    </>
  );
};

export default ScreverOcxProgram;
